import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .timerContent {
    width: 100%;
    display: flex;
    height: 60px;
    padding: 0;
    background-color: #032c62;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 6px 0;
  }

  .timerBlockSelo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .timerSelo {
    height: 100%;
  }

  .timerSelo img {
    max-height: 100%;
    max-width: 60px;
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    z-index: 0;
  }

  .timerHours {
    font-size: 30px;
    font-weight: bold;
  }

  .timerHours span {
    padding: 0 3px;
  }

  .timerText {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    margin: 0 30px 0 20px;
    letter-spacing: 1.5px;
  }

  @media only screen and (max-width: 767px) {
    .timerContent {
      height: 100%;
      // flex-direction: column;
      // // align-items: end;
      // background-size: cover !important;
      padding: 16px 0;
      display: block;
      text-align: center;
    }

    .timerHours {
      font-size: 22px;
    }

    .timerText {
      margin: 0;

      span {
        white-space: pre;
      }
    }

    .timerSelo {
      margin-right: 10px;
    }

    .timerBlockSelo {
      padding-bottom: 10px;
      text-align: left;

      .timerText {
        font-size: 14px;
        margin: 0;
        width: min-content;
      }

      ~ * {
        display: inline-block;
      }
    }

    .timerBox {
      margin: 0;
      width: 150px;
      text-align: center;
    }

    .timerHours,
    .timerBox {
      font-size: 22px;
      width: fit-content;
    }
  }
`;
