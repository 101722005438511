import { config } from 'process';

import React, { useEffect, useState } from 'react';

import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';

import { IState } from 'store';
import { IConfigApplicationProps } from 'store/modules/config';

import { Container } from './styles';

const Timer = () => {
  const { ultraDescontao } = useSelector<IState, IConfigApplicationProps>(
    state => state.config.config,
  );

  useEffect(() => {
    const getWidth = () => {
      if (window.innerWidth > 767) {
        setImgBackground(ultraDescontao?.cronometroImagemUrl);
      } else {
        setImgBackground(ultraDescontao?.cronometroImagemMobileUrl);
      }
    };

    getWidth();

    // eslint-disable-next-line no-restricted-globals
    onresize = () => {
      getWidth();
    };
  }, [ultraDescontao]);

  const [imgBackground, setImgBackground] = useState('');

  const dateFim = new Date(ultraDescontao?.cronometroFim);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (!completed) {
      const dias = () => {
        if (days > 0) {
          return days > 1 ? `${days} dias` : `${days} dia`;
        }
        return <></>;
      };

      return (
        <div className="timerHours">
          <span>{dias}</span> <span>{hours < 10 ? `0${hours}` : hours}</span>:
          <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
          <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      {ultraDescontao?.estaVigente && ultraDescontao?.cronometroAtivo && (
        <a href={ultraDescontao.cronometroUrl} style={{ width: '100%' }}>
          <Container>
            <div
              className="timerContent"
              style={{
                background: `url("${imgBackground}")`,
                color: `#${ultraDescontao.corTexto}`,
              }}
            >
              <div className="timerBlockSelo">
                {ultraDescontao.seloVigenteVitrineUrl && (
                  <div className="timerSelo">
                    <img
                      src={ultraDescontao.seloVigenteVitrineUrl}
                      alt="Selo Ultradescontão"
                    />
                  </div>
                )}
                <h3 className="timerText">
                  APROVEITE PARA COMPRAR
                  <span style={{ color: `#${ultraDescontao.corTextoBarato}` }}>
                    {' '}
                    + BARATO
                  </span>
                </h3>
              </div>
              <h3 className="timerText">
                {ultraDescontao.cronometroCallToAction}
              </h3>
              <div
                className="timerBox"
                style={{ color: `#${ultraDescontao.corCronometro}` }}
              >
                <Countdown date={dateFim} renderer={renderer} />
              </div>
            </div>
          </Container>
        </a>
      )}
    </>
  );
};

export default Timer;
